<template>
  <v-container fluid>
    <page-header title="權限設定"></page-header>

    <v-card class="mb-8">
      <v-card-text>
        <v-card-title class="page-title">管理員</v-card-title>

        <div class="px-5">
          <v-checkbox class="custom-checkbox" dense v-model="admin.user" label="用戶管理"></v-checkbox>
          <v-checkbox class="custom-checkbox" dense v-model="admin.job" label="工作管理"></v-checkbox>
          <v-checkbox class="custom-checkbox" dense v-model="admin.order" label="訂單管理"></v-checkbox>
          <v-checkbox class="custom-checkbox" dense v-model="admin.promotion" label="宣傳管理"></v-checkbox>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mb-8">
      <v-card-text>
        <v-card-title class="page-title">員工</v-card-title>

        <div class="px-5">
          <v-checkbox class="custom-checkbox" dense v-model="staff.user" label="用戶管理"></v-checkbox>
          <v-checkbox class="custom-checkbox" dense v-model="staff.job" label="工作管理"></v-checkbox>
          <v-checkbox class="custom-checkbox" dense v-model="staff.order" label="訂單管理"></v-checkbox>
          <v-checkbox class="custom-checkbox" dense v-model="staff.promotion" label="宣傳管理"></v-checkbox>
        </div>
      </v-card-text>
    </v-card>

    <v-btn
      depressed
      color="primary accent-4"
      class="white--text"
      type="submit"
      :loading="$store.getters.isLoading"
      @click="save()"
      >儲存</v-btn
    >
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'PermissionManage',
  components: {},
  data: () => ({
    admin: {
      user: true,
      job: true,
      order: true,
      promotion: true,
    },
    staff: {
      user: true,
      job: true,
      order: true,
      promotion: true,
    },
  }),
  methods: {
    ...mapActions(['setAlert']),
    async getPermissionData() {
      try {
        const user = this.getCurrentUserData()

        const data = await this.$Fetcher.GetSystemPermission(user.id, user.token)

        for (const key in this.admin) {
          if (data.admin.includes(key)) {
            this.admin[key] = true
          } else {
            this.admin[key] = false
          }
        }

        for (const key in this.staff) {
          if (data.staff.includes(key)) {
            this.staff[key] = true
          } else {
            this.staff[key] = false
          }
        }
      } catch (err) {
        if (err === 'noPermission') {
          this.$store.dispatch('toggleSnack', {
            type: 'error',
            message: err,
            redirect: 'NoPermission',
          })
        } else if (err === 'tokenExpired') {
          this.$store.dispatch('toggleSnack', {
            type: 'error',
            message: err,
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    async save() {
      const user = this.getCurrentUserData()
      if (user.user_type === 'admin') {
        this.$store.dispatch('setLoading', true)

        let payload = {
          admin: {},
          staff: {},
        }
        for (const key in this.admin) {
          payload['admin'][`${key}_management`] = this.admin[key]
        }
        for (const key in this.staff) {
          payload['staff'][`${key}_management`] = this.staff[key]
        }

        try {
          await this.$Fetcher.UpdateSystemPermission(payload, user.id, user.token)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '儲存成功',
            refresh: true,
          })
        } catch (err) {
          this.$common.error(err)
          this.setAlert({
            title: '儲存失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      } else {
        this.$store.dispatch('toggleSnack', {
          message: 'noPermission',
        })
      }
    },
  },

  async mounted() {
    this.$store.dispatch('setInitLoading', true)
    await this.getPermissionData()
  },
}
</script>
